<template>
    <section id="features" class="grey lighten-3" >
        <div class="py-12"></div>
        <v-container class="text-center">
          <h2 class="display-2 font-weight-bold mb-3">SERVICIOS</h2>
          <v-responsive class="mx-auto mb-12" width="56">
            <v-divider class="mb-1"></v-divider>
            <v-divider></v-divider>
          </v-responsive>
          <v-row>
            <v-col v-for="({ icon, title, text, altText, picture }, i) in features" :key="i" cols="12" md="4">                
                <v-hover>
                    <v-card  class="py-12 px-4 mx-auto" color="grey lighten-5" flat slot-scope="{ hover }" >                                               
                        <v-theme-provider dark>
                        <div>                            
                            <v-avatar color="red darken-1" size="88">
                            <v-icon large v-text="icon"></v-icon>
                            </v-avatar>
                            
                        </div>
                        </v-theme-provider>
                        <v-card-title class="justify-center font-weight-black text-uppercase" v-text="title"></v-card-title>
                        <v-card-text class="subtitle-1" v-text="text" >                            
                        </v-card-text>
                        <!--
                        <v-img  src="../assets/code.avif"></v-img>
                        <v-img  src="../assets/kreadores-ly.svg"></v-img>
                        -->
                        <v-img  :src="picture"></v-img>
                        <v-slide-x-reverse-transition>
                            <div
                                v-if="hover"
                                class="d-flex  transition-fast-in-fast-out red darken-1 v-card--reveal display-3 white--text text-center   text-md-body-1"
                                style="height: 20%;"                                
                            >
                                <p>{{ altText }}</p>     
                            </div>
                        </v-slide-x-reverse-transition>                        
                    </v-card>                    
                </v-hover>                
            </v-col>
          </v-row>
        </v-container>
        <div class="py-12"></div>
      </section>
</template>

<script>

export default {
	
    data() {
        return {
            features: [
                {
                    //icon: 'mdi-account-group-outline',
                    icon: 'mdi-web',
                    title: 'Desarrollo de Software',
                    text: 'Desarrollo de aplicaciones web, móviles y desktop. Integraciones de APIs y servicios externos.',
                    altText: 'PHP, ASP, Laravel, Vue, Livewire, Javascript, HTML, CSS, ', 
                    picture: require('../assets/code.png')
                },                
                {
                    icon: 'mdi-cloud-sync',
                    title: 'Cloud Services',
                    text: 'Expertos en  implementación y administración de servicios en la nube, como AWS, Azure, Google Cloud, etc.',
                    altText:'Microsoft Azure, AWS, VMware, Hypervisor',
                    picture: require('../assets/servers.png')

                },
                {
                    icon: 'mdi-shield-lock',
                    title: 'Seguridad',
                    text: 'Seguridad de la información, Auditoría de seguridad, Auditoría de sistemas, Auditoría de aplicaciones, Respaldos, firewalls, etc.',
                    altText:'Fortigate, Palo Alto, McAfee ',
                    picture: require('../assets/fingerprint.jpg')
                },                
            ],      
        }
    },
}
</script>

<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  left: 0%;
  justify-content: center;
  opacity: .9;
  position: relative;
  width: 100%;
  
}
</style>