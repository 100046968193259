var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"grey lighten-3",attrs:{"id":"features"}},[_c('div',{staticClass:"py-12"}),_c('v-container',{staticClass:"text-center"},[_c('h2',{staticClass:"display-2 font-weight-bold mb-3"},[_vm._v("SERVICIOS")]),_c('v-responsive',{staticClass:"mx-auto mb-12",attrs:{"width":"56"}},[_c('v-divider',{staticClass:"mb-1"}),_c('v-divider')],1),_c('v-row',_vm._l((_vm.features),function(ref,i){
var icon = ref.icon;
var title = ref.title;
var text = ref.text;
var altText = ref.altText;
var picture = ref.picture;
return _c('v-col',{key:i,attrs:{"cols":"12","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return _c('v-card',{staticClass:"py-12 px-4 mx-auto",attrs:{"color":"grey lighten-5","flat":""}},[_c('v-theme-provider',{attrs:{"dark":""}},[_c('div',[_c('v-avatar',{attrs:{"color":"red darken-1","size":"88"}},[_c('v-icon',{attrs:{"large":""},domProps:{"textContent":_vm._s(icon)}})],1)],1)]),_c('v-card-title',{staticClass:"justify-center font-weight-black text-uppercase",domProps:{"textContent":_vm._s(title)}}),_c('v-card-text',{staticClass:"subtitle-1",domProps:{"textContent":_vm._s(text)}}),_c('v-img',{attrs:{"src":picture}}),_c('v-slide-x-reverse-transition',[(hover)?_c('div',{staticClass:"d-flex  transition-fast-in-fast-out red darken-1 v-card--reveal display-3 white--text text-center   text-md-body-1",staticStyle:{"height":"20%"}},[_c('p',[_vm._v(_vm._s(altText))])]):_vm._e()])],1)}}],null,true)})],1)}),1)],1),_c('div',{staticClass:"py-12"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }